import React,{useState,useEffect} from 'react'
import AdminTemplate from '../Components/Template/AdminTemplate'

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from '../Components/DataTable';
import { Container, Grid, Paper,Tooltip } from '@mui/material';
import SubmitButton from '../Components/InputFields/SubmitButton';
import TextBox from '../Components/InputFields/TextBox';
import { Button, Space } from 'antd';

import { useAddIeltsDateMutation, useDeleteIeltsDateMutation, useGetAllDatesQuery, useUpdateIeltsDateMutation } from '../Services/modules/ielts';

import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { useNavigate } from 'react-router-dom';

function IeltsDate() {
  const [pageInfo, setPageInfo] = useState({ current: 1, pageSize: 10 }); // Initial pagination info
  const navigate = useNavigate(); 
const{data,isSuccess,isLoading,error}=useGetAllDatesQuery({page_index: pageInfo.current - 1,page_size: pageInfo.pageSize})
const [AddIeltsDate, { data:createData, isSuccess:createSuccess, isLoading:createLoading, isError:createError }] = useAddIeltsDateMutation();
const [UpdateIeltsDate, { data:updateData, isSuccess:updateSuccess, isLoading:updateLoading, isError:updateError }] = useUpdateIeltsDateMutation();
const [DeleteIeltsDate, { data:deleteData, isSuccess:deleteSuccess, isLoading:deleteLoading, isError:deleteError }] = useDeleteIeltsDateMutation();

const[isupdate,setIsUpdate]=useState()
const[isDeleteAlert,setDeleteAlert]=useState(false)
const[allIeltsDates,setAlIeltsDates]=useState([])
const[date_id,setDateId]=useState()
const[ielts_date,setIeltsDate]=useState()
const[ielts_day,setIeltsDay]=useState()

const[totalCount,setTotalCount]=useState(0)

const columns = [ 
   
    { 
    key: "ielts_date", 
    title: "Date", 
    dataIndex: "ielts_date", 
    }, 
    { 
        key: "ielts_day", 
        title: "Day", 
        dataIndex: "ielts_day", 
        },           
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">    
         <a onClick={()=>addLinks(record)} style={{color:"green"}}>
            <Tooltip title="Add Test Links">
          <CurrencyRupeeIcon style={{fontSize:'medium'}}/> 
          </Tooltip>
          </a>   
        <a onClick={()=>openDeleteAlert(record)} style={{color:"green"}}>
          <DeleteIcon style={{fontSize:'medium'}}/> 
          </a>  
          <a onClick={()=>updateIeltsDate(record)} style={{color:"green"}}>
          <EditIcon style={{fontSize:'medium'}}/> 
          </a>     
         
        </Space>
      ),
    },
    
    ] 
useEffect(()=>{

    if(isSuccess)
    {
        console.log("Ielts Date data",data)
        setAlIeltsDates(data.data)
        setTotalCount(data.totalRows)
    }
},[data])
useEffect(()=>{
    console.log("createData",createData)
    if(createSuccess)
    {            
        toast.success(createData.message)
        setIeltsDate("")
        setIeltsDay("")
        setDateId("")        
    }
    else
    {       
     //   toast.success("Something went Wrong")
     setIeltsDate("")
        setIeltsDay("")
        setDateId("")  

    }
},[createData])
useEffect(()=>{

    if(updateSuccess)
    {
      setIeltsDate("")
      setIeltsDay("")
      setDateId("")  
          setIsUpdate(false)  
         toast.success(updateData.message)
    }
    else
    {
      setIeltsDate("")
      setIeltsDay("")
      setDateId("")  
        setIsUpdate(false)        
       // toast.success("Something went Wrongg")
    }
},[updateData])
useEffect(()=>{
    if(deleteSuccess)
    {
      setIeltsDate("")
      setIeltsDay("")
      setDateId("")  
        setDeleteAlert(false)
        toast.success(deleteData.message)
    }
    else
    {
        setDeleteAlert(false)
        setIeltsDate("")
        setIeltsDay("")
        setDateId("")  
    }
},[deleteData])
const openDeleteAlert=(record)=>{
    setDateId(record._id)
    setDeleteAlert(true)
}
const deleteIeltsDate=()=>{
    console.log("In delete quote")
    DeleteIeltsDate({date_id:date_id})
}
const updateIeltsDate=(record)=>{
        setDateId(record._id) 
        setIeltsDate(record.homework_title)
        setIeltsDay(record.homework_task)      
        setIsUpdate(true)
}
const onSubmit=()=>{
    if(isupdate)
    {
        UpdateIeltsDate({ielts_date, ielts_day,date_id})
    }
    else
    {
        AddIeltsDate({ielts_date, ielts_day})
    }
}
const handlePageChange = (pagination) => {
  setPageInfo({
      current: pagination.current,
      pageSize: pagination.pageSize,
  });
};
const addLinks = (record) => {
  navigate(`/ieltslink?date_id=${record._id}&ielts_date=${record.ielts_date}`);
};
    const ieltsTestLinkDateScreen=()=>{
        return(
            <>
            <Container alignItems="center" sx={{mt:5}}>

      <ToastContainer />

            <Dialog
        open={isDeleteAlert}
       onClose={()=>setDeleteAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Record"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Do you really want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
          onClick={()=>setDeleteAlert(false)}
          >No</Button>
          <Button onClick={()=>deleteIeltsDate()} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Grid
           container
           spacing={5}
           direction="row"
           alignItems="center"
           justify="center"
           //style={{ minHeight: '100vh' }}
         >
                  <Grid item xs={12} md={5} lg={5}>
                  <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
                             <TextBox             
             label={"IELTS Test Link Date"}
             id={"ielts_date"}
             name={"ielts_date"}
             autoFocus={true}                         
            value={ielts_date}  
            isSelect={false}      
            onChange={(e)=>setIeltsDate(e.target.value)}
            />
             <TextBox             
             label={"Add Day"}
             id={"ielts_day"}
             name={"ielts_day"}
             autoFocus={false}                         
            value={ielts_day}  
            isSelect={false}      
            onChange={(e)=>setIeltsDay(e.target.value)}
            />
            
             <SubmitButton 
              caption={isupdate? "update": "Save"} 
             //isLoading={isLoading}
              button_color={'green'}
              onClick={()=>onSubmit()}
              />
            
                         </Paper>
                  </Grid>
                  <Grid item xs={12} md={7} lg={7}>
                  <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
                             <DataTable 
                  columns={columns}
                  Data={!isLoading? allIeltsDates:[]}
                  totalCount={totalCount}
                  handleChange={handlePageChange}
                  
                  />
                         </Paper>
                  </Grid>

         </Grid>
      </Container>

            </>
        )
    }
  return (
   <AdminTemplate>
    {ieltsTestLinkDateScreen()}
    </AdminTemplate>
  )
}

export default IeltsDate