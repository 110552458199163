import React, { useState,useEffect } from 'react'
import { Grid, Paper,Container, Tooltip } from '@mui/material'
import AdminTemplate from '../Components/Template/AdminTemplate'
import TextBox from '../Components/InputFields/TextBox'
import SubmitButton from '../Components/InputFields/SubmitButton'
import { useCreateCourseMutation,useGetAllCoursesQuery, useGetAllRecordedCoursesStudentsQuery } from '../Services/modules/course';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from '../Components/DataTable'
import Mail from '@mui/icons-material/Mail';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { Select, Input, Table, Button, Icon,Space } from "antd";
import { useDispatch } from 'react-redux';
import { updateCourseData } from '../Store/CourseData';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate  } from "react-router-dom";
import { useCreateExistingStudentMutation, useCreateStudentMutation, useDeleteEnrolledStudentMutation, useGetAllEnrolledStudentsQuery, useGetAllStudentsQuery, useUpdateExistingStudentMutation, useUpdateStudentMutation } from '../Services/modules/liveCourses'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useGetStudentByUsernameMutation } from '../Services/modules/auth'
import { FlashlightOffRounded } from '@mui/icons-material'

function ReportRecordedCourseStudents() {
  const[isupdateRecord,setUpdateRecord]=useState(false);
  const [deleteDialogOpen,setDeleteDialogOpen]=useState(false)
const[studentId,setStudentId]=useState("")
  const navigate=useNavigate ()
  const dispatch = useDispatch();

  const { 
    data: students= [],  
    isLoading:loading=true,
    isSuccess:getAllStudentsSuccess=false
   } = useGetAllRecordedCoursesStudentsQuery();
  

   const columns = [ 
   
   
    { 
      key: "first_name", 
      title: "Student Name", 
      render: (_, record) => `${record.first_name} ${record.last_name}`,
     // dataIndex: "first_name", 
      },
  
    { 
    key: "enrolled_courses", 
    title: "Course Name", 
    dataIndex: "enrolled_courses", 
    },    
      { 
        key: "mobile_number", 
        title: "Country", 
        dataIndex: "mobile_number", 
        }, 
        { 
            key: "mobile_number", 
            title: "Country", 
            dataIndex: "mobile_number", 
            }, 
      
        
    // {
    //   title: 'Action',
    //   key: 'action',
    //   render: (_, record) => (
    //     <Space size="middle">    
               
    //       {/* <a onClick={()=>createSchoolProfile(record)} style={{color: "blue"}}>Profile </a>  */}
    //       <a onClick={()=>editStudent(record)} style={{color: "blue"}}><EditIcon style={{fontSize:'medium'}}/> </a>
    //       <a onClick={()=>deleteStudentConfirmAlert(record)} style={{color: "red"}}><DeleteIcon style={{fontSize:'medium'}}/></a>
        
    //     </Space>
    //   ),
    // },
   
    ] 
   useEffect(()=>{
    console.log("recorded courses studnet list",students)
   },[students])
    const ReportRecordedCourseStudentsScreen=()=>{
        return(
            <Container alignItems="center" sx={{mt:5}}>
               
    
            <Grid
           container
           spacing={5}
           direction="row"
           alignItems="center"
           justify="center"
           //style={{ minHeight: '100vh' }}
         >
                 
                  <Grid item xs={12} md={12} lg={12}>
                  <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
                         
                            <DataTable 
                  columns={columns}
                  Data={!loading? students.allStudents:[]}/>
                       
                         </Paper>
                  </Grid>
         
                 </Grid>
                 </Container>
        )
    }
  return (
   <AdminTemplate>
    {ReportRecordedCourseStudentsScreen()}
   </AdminTemplate>
  )
}

export default ReportRecordedCourseStudents