import { api } from '../../api'

import addYoutubeVideos from './addYoutubeVideos'
import deleteYoutubeVideo from './deleteYoutubeVideo'
import getAllYoutubeVideos from './getAllYoutubeVideos'

export const youtubeVideosApi = api.injectEndpoints({
    endpoints: build => ({
        AddYoutubeVideos: addYoutubeVideos(build),
        GetAllYoutubeVideos:getAllYoutubeVideos(build)     ,
        DeleteYoutubeVideo:   deleteYoutubeVideo(build) 
       
    }),
    overrideExisting: true,
    //providesTags: ['Research']
})
export const {
    useAddYoutubeVideosMutation, 
    useGetAllYoutubeVideosQuery,
    useDeleteYoutubeVideoMutation
  
} = youtubeVideosApi
