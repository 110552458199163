export default build =>
    build.query({
        tagTypes: ['allIeltsDates'],
        keepUnusedDataFor: 1,
        query(payload) {                       
            return {
                url: `/ielts/getAllIeltsDates?page_index=${payload.page_index}&page_size=${payload.page_size}`,
                method: 'GET',
                //body: payload,
            };
           
        },
       providesTags: ['allIeltsDates']
    })
