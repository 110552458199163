import React,{useState,useEffect} from 'react'
import AdminTemplate from '../Components/Template/AdminTemplate'
import { useCreateQuoteMutation, useCreateWordMutation, useDeleteQuoteMutation, useDeleteWordMutation, useGetAllQuotesQuery, useGetAllWordsQuery, useUpdateQuoteMutation, useUpdateWordMutation } from '../Services/modules/otherinfo'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from '../Components/DataTable';
import { Container, Grid, Paper } from '@mui/material';
import SubmitButton from '../Components/InputFields/SubmitButton';
import TextBox from '../Components/InputFields/TextBox';
import { Button, Space } from 'antd';

function OtherInfoWord() {
  const [pageInfo, setPageInfo] = useState({ current: 1, pageSize: 10 }); // Initial pagination info
   
const{data,isSuccess,isLoading,error}=useGetAllWordsQuery({page_index: pageInfo.current - 1,page_size: pageInfo.pageSize,category:"words"})
const [CreateWord, { data:createData, isSuccess:createSuccess, isLoading:createLoading, isError:createError }] = useCreateWordMutation();
const [UpdateWord, { data:updateData, isSuccess:updateSuccess, isLoading:updateLoading, isError:updateError }] = useUpdateWordMutation();
const [DeleteWord, { data:deleteData, isSuccess:deleteSuccess, isLoading:deleteLoading, isError:deleteError }] = useDeleteWordMutation();

const[isupdate,setIsUpdate]=useState()
const[isDeleteAlert,setDeleteAlert]=useState(false)
const[allWords,setAllWords]=useState([])
const[word_id,setWordId]=useState()
const[word,setWord]=useState("")
const[meaning,setMeaning]=useState("")
const[totalCount,setTotalCount]=useState(0)

const columns = [ 
   
    { 
    key: "word", 
    title: "Word", 
    dataIndex: "word", 
    }, 
    { 
        key: "meaning", 
        title: "Meaning", 
        dataIndex: "meaning", 
        }, 
      
  
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">    
        <a onClick={()=>openDeleteAlert(record)} style={{color:"green"}}>
          <DeleteIcon style={{fontSize:'medium'}}/> 
          </a>  
          <a onClick={()=>updateWord(record)} style={{color:"green"}}>
          <EditIcon style={{fontSize:'medium'}}/> 
          </a>     
         
        </Space>
      ),
    },
    
    ] 
useEffect(()=>{

    if(isSuccess)
    {
        console.log("words data",data)
        setAllWords(data.data)
        setTotalCount(data.totalRows)
    }
},[data])
useEffect(()=>{
    console.log("createData",createData)
    if(createSuccess)
    {            
        toast.success(createData.message)
        setWord("")
    }
    else
    {       
     //   toast.success("Something went Wrong")
     setWord("")

    }
},[createData])
useEffect(()=>{

    if(updateSuccess)
    {
          setWordId("") 
          setWord("")
          setMeaning("")
        setIsUpdate(false)  
        toast.success(updateData.message)
    }
    else
    {
        setWordId("") 
        setWord("")
        setMeaning("")

        setIsUpdate(false)        
       // toast.success("Something went Wrongg")
    }
},[updateData])
useEffect(()=>{
    if(deleteSuccess)
    {
        setWordId("")
        setDeleteAlert(false)
        toast.success(deleteData.message)
    }
    else
    {
        setDeleteAlert(false)
        setWordId("")
    }
},[deleteData])
const openDeleteAlert=(record)=>{
    setWordId(record._id)
    setDeleteAlert(true)
}
const deleteWord=()=>{
    console.log("In delete quote")
    DeleteWord({word_id:word_id})
}
const updateWord=(record)=>{
    setWord(record.word)
    setMeaning(record.meaning)

    setWordId(record._id)
    setIsUpdate(true)
}
const onSubmit=()=>{
    if(isupdate)
    {
        UpdateWord({word:word,wordId:word_id,meaning:meaning})
    }
    else
    {
        CreateWord({word:word,meaning:meaning,category:"words"})
    }
}
const handlePageChange = (pagination) => {
  setPageInfo({
      current: pagination.current,
      pageSize: pagination.pageSize,
  });
};
    const otherInfoWordScreen=()=>{
        return(
            <>
            <Container alignItems="center" sx={{mt:5}}>

      <ToastContainer />

            <Dialog
        open={isDeleteAlert}
       onClose={()=>setDeleteAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Record"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Do you really want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
          onClick={()=>setDeleteAlert(false)}
          >No</Button>
          <Button onClick={()=>deleteWord()} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Grid
           container
           spacing={5}
           direction="row"
           alignItems="center"
           justify="center"
           //style={{ minHeight: '100vh' }}
         >
                  <Grid item xs={12} md={5} lg={5}>
                  <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
                             <TextBox             
             label={"Add Word"}
             id={"word"}
             name={"word"}
             autoFocus={true}                         
            value={word}  
            isSelect={false}      
            onChange={(e)=>setWord(e.target.value)}
            />
             <TextBox             
             label={"Add Meaning"}
             id={"meaning"}
             name={"meaning"}
             autoFocus={false}                         
            value={meaning}  
            isSelect={false}      
            onChange={(e)=>setMeaning(e.target.value)}
            />
             <SubmitButton 
              caption={isupdate? "update": "Save"} 
             //isLoading={isLoading}
              button_color={'green'}
              onClick={()=>onSubmit()}
              />
            
                         </Paper>
                  </Grid>
                  <Grid item xs={12} md={7} lg={7}>
                  <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
                             <DataTable 
                  columns={columns}
                  Data={!isLoading? allWords:[]}
                  totalCount={totalCount}
                  handleChange={handlePageChange}
                  
                  />
                         </Paper>
                  </Grid>

         </Grid>
      </Container>

            </>
        )
    }
  return (
   <AdminTemplate>
    {otherInfoWordScreen()}
    </AdminTemplate>
  )
}

export default OtherInfoWord