import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { useAddIeltsTestLinksMutation, useDeleteIeltsLinkMutation, useGetIeltsTestLinksQuery } from '../Services/modules/ielts';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from '../Components/DataTable';
import SubmitButton from '../Components/InputFields/SubmitButton';
import TextBox from '../Components/InputFields/TextBox';
import { Button, Space } from 'antd';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid, Paper,Container, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material'
import AdminTemplate from '../Components/Template/AdminTemplate';

function IeltsLinks() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const date_id = queryParams.get('date_id');
    const ielts_date = queryParams.get('ielts_date');
    const{data,isSuccess,isLoading,error}=useGetIeltsTestLinksQuery({ielts_date_id: date_id})
const [AddIeltsLink, { data:createData, isSuccess:createSuccess, isLoading:createLoading, isError:createError }] = useAddIeltsTestLinksMutation();
const [DeleteIeltsLink, { data:deleteData, isSuccess:deleteSuccess, isLoading:deleteLoading, isError:deleteError }] = useDeleteIeltsLinkMutation();
const[isDeleteAlert,setDeleteAlert]=useState(false)

    const[testLinksData,setTestLinksData]=useState([])
    const[ieltsTestLink,setIeltsTestLink]=useState("")
    const[ieltsTestLinkCaption,setIeltsTestLinkCaption]=useState("")
    const[link_id,setLinkId]=useState("")
    const[showDialogDetails,setShowDialogDetails]=useState(false)
    const[viewVideoDetails,setViewVideoDetails]=useState({})
    const columns = [ 
   
        { 
          key: "caption", 
          title: "Caption", 
          dataIndex: "caption", 
          }, 
       
        {
          title: 'Action',
          key: 'action',
          render: (_, record) => (
            <Space size="middle">    
            <a onClick={()=>showDetails(record)} style={{color: "blue"}}><RemoveRedEyeIcon style={{fontSize:'medium'}}/> </a> 
    
            <a onClick={()=>openDeleteAlert(record)} style={{color:"green"}}>
              <DeleteIcon style={{fontSize:'medium'}}/> 
              </a>    
             
            </Space>
          ),
        },        
        ] 
    useEffect(()=>{
        if(isSuccess)
        {
            console.log("ielts test linkd data",data)
            setTestLinksData(data.data)

        }
    },[data])
    useEffect(()=>{
        if(deleteSuccess)
        {
            setIeltsTestLink("")
            setIeltsTestLinkCaption("")            
              setDeleteAlert(false)
              toast.success(deleteData.message)
        }
    },[deleteData])
    useEffect(()=>{
        if(createSuccess)
        {
            setIeltsTestLink("")
            setIeltsTestLinkCaption("")            
             
              toast.success(createData.message)
        }
    },[createData])

    const openDeleteAlert=(record)=>{
        setLinkId(record.link_id)
        console.log("record in dlete link",record)
        setDeleteAlert(true)
    }
    const deleteIeltsLink=()=>{
        console.log("In delete quote")
        DeleteIeltsLink({link_id:link_id,date_id:date_id})
    }
    const showDetails=(record)=>{
        setViewVideoDetails(record)
        setShowDialogDetails(true);
       }
       const closeDetailDialog=()=>{  
        setShowDialogDetails(false)    
       }
       function onSubmit (){    
        //  console.log("notes file",file)    
        AddIeltsLink({
            date_id:date_id,
            caption:ieltsTestLinkCaption,
            ieltsTestLink:ieltsTestLink
        })
  
       }
     

   
        const ieltsLinkScreen=()=>{
            return(
<>
<Container alignItems="center" sx={{mt:5}}>
<ToastContainer />
<Dialog
        open={isDeleteAlert}
       onClose={()=>setDeleteAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Record"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Do you really want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
          onClick={()=>setDeleteAlert(false)}
          >No</Button>
          <Button onClick={()=>deleteIeltsLink()} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showDialogDetails}
        onClose={closeDetailDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Testimonials Details"}
        </DialogTitle>
        <DialogContent>
      
        <Grid item xs={12} md={12} lg={12}>
        caption: {viewVideoDetails.caption}
         </Grid>
       
       
         <Grid item xs={12} md={12} lg={12}>
         <iframe width="560" height="315" 
        src={`https://www.youtube.com/embed/${viewVideoDetails.video_id}`}                    
        frameborder="0" allowfullscreen></iframe>  
         </Grid>
        
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDetailDialog}>Close</Button>
        
        </DialogActions>
      </Dialog>
      <Grid
           container
           spacing={5}
           direction="row"
           alignItems="center"
           justify="center"
           //style={{ minHeight: '100vh' }}
         >
 <Grid item xs={12} md={5} lg={5}>
 <Grid item xs={12} md={12} lg={12}>
                      <legend style={{fontWeight:"600",marginBottom:"20px"}}>
                      Add Test Link For Date {ielts_date}</legend>
                      </Grid>
 <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >

                             <TextBox             
             label={"Add test link"}
             id={"ieltsTestLink"}
             name={"ieltsTestLink"}
             autoFocus={true}                         
            value={ieltsTestLink}  
            isSelect={false}      
            onChange={(e)=>setIeltsTestLink(e.target.value)}
            />
             <TextBox             
             label={"Add test caption"}
             id={"ieltsTestLinkCaption"}
             name={"ieltsTestLinkCaption"}
             autoFocus={false}                         
            value={ieltsTestLinkCaption}  
            isSelect={false}      
            onChange={(e)=>setIeltsTestLinkCaption(e.target.value)}
            />
             <SubmitButton 
              caption={"Save"}             
              button_color={'green'}
              onClick={()=>onSubmit()}
              />
                            </Paper>
 </Grid>

 <Grid item xs={12} md={7} lg={7}>
                  <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
                             <DataTable 
                  columns={columns}
                  Data={!isLoading? testLinksData:[]}                                   
                  />
                         </Paper>
                  </Grid>
</Grid>
</Container>
</>
            )
        }
  return (
    <AdminTemplate>{ieltsLinkScreen()}</AdminTemplate>
  )
}

export default IeltsLinks