export default build =>
    build.query({
        tagTypes: ['testLinksByDate'],
        keepUnusedDataFor: 1,
        query(payload) {
                       
            return {
                url: `/ielts/getAllIeltsLinksByDate?ielts_date_id=${payload.ielts_date_id}`,
                method: 'GET',
                //body: payload,
            };
           
        },
       providesTags: ['testLinksByDate']
    })
