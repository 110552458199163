export default build =>
    build.mutation({
        query(payload) {
            console.log("payload",payload) //date_id, link_id
            return {
                url: '/ielts/deleteIeltsLinkById',
                method: 'POST',
                body: payload,
               
            };
        },
        invalidatesTags: ['testLinksByDate'],
    })
