import { api } from "../../api";
import addIeltsDate from "./addIeltsDate";
import addIeltsTestLinks from "./addIeltsTestLinks";
import deleteIeltsDate from "./deleteIeltsDate";
import deleteIeltsLink from "./deleteIeltsLink";
import getAllDates from "./getAllDates";
import getIeltsTestLinksByDate from "./getIeltsTestLinksByDate";
import updateIeltsDate from "./updateIeltsDate";

export const ieltsApi=api.injectEndpoints({
    endpoints:build=>({
        GetAllDates:getAllDates(build),
        AddIeltsDate:addIeltsDate(build),
        UpdateIeltsDate:updateIeltsDate(build),
        DeleteIeltsDate:deleteIeltsDate(build),

        AddIeltsTestLinks:addIeltsTestLinks(build),
        DeleteIeltsLink:deleteIeltsLink(build),
        GetIeltsTestLinks:getIeltsTestLinksByDate(build)
    }),
    overrideExisting: true,
})

export const{
    useGetAllDatesQuery,
    useAddIeltsDateMutation,
    useUpdateIeltsDateMutation,
    useDeleteIeltsDateMutation,

    useAddIeltsTestLinksMutation,
    useDeleteIeltsLinkMutation,
    useGetIeltsTestLinksQuery,
}=ieltsApi