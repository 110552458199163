import React,{useEffect,useState,useRef} from 'react'

import { useDispatch,useSelector } from 'react-redux';
import AdminTemplate from '../Components/Template/AdminTemplate';
import { Link } from 'react-router-dom';
//import DashboardSchool from './SchoolAdmin/DashboardSchool';

//import SuperadminDashboard from './SuperAdmin/SuperadminDashboard';
//import { useCreateSchoolMutation } from '../Services/modules/school';

function Reports() {
  const isSuperAdmin = useSelector(state => state.userData.user_data.role == "super_admin" ? true : false);
const ReportsScreen=()=>{
  
    if(isSuperAdmin)
    { 
        return (
      
       <>
        <div className="dashboard-container">
       
      <div className="card">
      <Link to='/recordedcoursestudnets'  style={{textDecoration:'none'}}>
        <h3>Recorded Course Students List</h3>
        </Link>
      </div>
      
      
     
    </div>
       </>
      
     )}
    
  
}
  if(isSuperAdmin)
 { 
    return (
   
     <AdminTemplate>
      {ReportsScreen()}
     </AdminTemplate>
   
  )}
 
}

export default Reports